import * as React from "react"
import Layout from "../components/layout"
import JobPostsFilter from "../components/jobPostsFilter"
import Seo from "../components/seo"
import JobsNav from "../components/jobsNav"

/*

Categories:

chefs = 2
front-of-house = 9
sales-marketing-events = 11
operations = 13
international = 4 


*/

const SalesMarketing = () => (
  <Layout
    jobPage={true}
    splashPage={false}
    pageTitle="VACANCIES -  SALES MARKETING EVENTS"
  >
    <Seo title="Vacancies chefs" />
    <JobsNav link={"sales"} />
    <JobPostsFilter category={11} />
  </Layout>
)

export default SalesMarketing
